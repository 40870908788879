import {Delete, get, post, put} from "@/api/axios";

export const list = params => get('/web/base/pc/list',params)

export const add = params => post('/web/base/pc/add',params)

export const deleteNew = params => Delete('/web/base/pc/delete/' + params.id, '')

export const view = params => get('/web/base/pc/view/' + params.id, '')

export const update = params => put('/web/base/pc/update',params)